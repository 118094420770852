.searchPage {
    .ais-SearchBox {
        width: max(50%, 300px);
    }
    .searchInput {
        font: inherit;
        letter-spacing: inherit;
        color: currentColor;
        padding: 4px 0 5px;
        border: 0;
        box-sizing: content-box;
        background: none;
        height: 1.4375em;
        margin: 0;
        -webkit-tap-highlight-color: transparent;
        display: block;
        min-width: 0;
        width: 100%;
        -webkit-animation-name: mui-auto-fill-cancel;
        animation-name: mui-auto-fill-cancel;
        -webkit-animation-duration: 10ms;
        animation-duration: 10ms;
        padding: 16.5px 14px;
        border-radius: 5px;
        border: 2px solid black;
        &:hover {
            border: 2px solid #1976d2;
        }
    }

    .previewHolder{
        width: 100%;
        height: 100px;
        position: relative;
    }

    .previewFrame{
        width: 100%;
        height: 100%;
    }

    .previewCover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        cursor: pointer;
        padding: 1px;
        &.noAccessCode {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-width: 2px;
            border-style: inset;
            border-color: initial;
            border-image: initial;
            overflow-clip-margin: content-box !important;
            overflow: clip !important;
            justify-content: center;
            width: 100%;
            img {
                max-width: calc(100% - 75px);
                object-fit: contain;
                padding: 8px;
                max-height: 50px;
            }
        }
    }


    .searchForm {
        display: flex;
        gap: 10px;
    }

    .searchReset {
        display: none;
    }

    .searchIcon {
        fill: white;
        height: 15px;
        width: 15px;
    }

    .searchButton {
        &:hover {
            -webkit-text-decoration: none;
        text-decoration: none;
        background-color: #1565c0;
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
        }
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0;
        border: 0;
        margin: 0;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: middle;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: inherit;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        min-width: 64px;
        padding: 6px 16px;
        border-radius: 4px;
        -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        color: #fff;
        background-color: #1976d2;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    }
}