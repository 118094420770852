.footer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    .footerLinkHeader {
        font-weight: bold;
        margin-bottom: 8px;
        font-size: 1.2em;
        padding: 4px;
    }

    .footerLinks {
        cursor: pointer;
        margin-top: 8px;
        padding: 4px;
    }
}