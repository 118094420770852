.rdrDateRangeWrapper:focus-within,
.rdrDateRangeWrapper:hover {
    .rdrDateDisplayWrapper {
        border-radius: 4px 4px 0 0;
    }

    .rdrMonthAndYearWrapper,
    .rdrMonths.rdrMonthsVertical {
        display: flex;
    }
}

.rdrMonthAndYearWrapper,
.rdrMonths.rdrMonthsVertical {
    display: none;
    background: #f0f2f8;
}

.rdrMonth {
    margin: auto;
}

.rdrMonths {
    border-radius: 0 0 4px 4px;
}

.rdrDateDisplayWrapper {
    width: 400px;
    border-radius: 4px;
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
    background: #80808017;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 3px;
    margin-bottom: 10px;
}

.rdrDateDisplayItem input {
    color: black;
}

.caption {
    font-size: 0.75em;
}

.react-multi-email [data-tag] {
    height: 30px;
}

.react-multi-email [data-tag] [data-tag-item] {
    overflow: visible;
}

.react-multi-email {
    background: unset;
    border-color: gray;
    color: unset;

    &.focused {
        background: unset;
    }
}

.react-multi-email.empty>span[data-placeholder] {
    color: unset;
}

.teamOptionDropdown {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
}

@media (max-width: 415px) {
    .dateRange {
        scale: 0.9;
    }
}
@media (max-width: 350px) {
    .dateRange {
        scale: 0.8;
    }
}