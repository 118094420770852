.previewHolder {
    width: 100%;
    height: 200px;
    position: relative;
}

.previewFrame {
    width: 100%;
    height: 100%;
}

.previewCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 1px;
}

.draftBadge .MuiBadge-badge {
    right: 36px
}

.pointer {
    cursor: pointer;
}