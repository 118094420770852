.react-multi-email {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    gap: 3px;
    input {
        font: inherit;
        letter-spacing: inherit;
        color: currentColor;
        padding: 4px 0 5px;
        border: 0;
        box-sizing: content-box;
        background: none;
        height: 1.4375em;
        margin: 0;
        -webkit-tap-highlight-color: transparent;
        display: block;
        min-width: 0;
        width: 100%;
        -webkit-animation-name: mui-auto-fill-cancel;
        animation-name: mui-auto-fill-cancel;
        -webkit-animation-duration: 10ms;
        animation-duration: 10ms;
        padding: 16.5px 14px;
    }
    &:hover {
        border-color: rgba(0, 0, 0, 0.87);
    }
    &:focus-within {
        border: rgb(0, 95, 204) 2px solid !important;
    }
}